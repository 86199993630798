<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>
        <v-col md="12" v-if="true">
          <h3 class="filtertitle">Filter {{ headercontent.title }}</h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="fullname"
                  label="Company Name"
                  outlined
                  dense
                  clearable
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                >
                  Filter {{ headercontent.title }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col md="12">
          <v-simple-table class="table" v-if="!allLoadingAuth">
            <template v-slot:default v-if="allCompanyUser.results">
              <thead>
                <tr>
                  <th class="text-left">S/N</th>
                  <th class="text-left">Name</th>
                  <th class="text-left">Company Phone</th>
                  <th class="text-left">Company Email</th>
                  <th class="text-left">Payment Statuts</th>
                  <th class="text-left">Add Job</th>
                  <th class="text-right" colspan="2">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in allCompanyUser.results.list"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.fullname }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.email }}</td>
                  <td>
                    <v-switch
                      v-model="item.paymentStatus"
                      @change="
                        () =>
                          updateCompany({
                            userHandle: item.userHandle,
                            paymentStatus: item.paymentStatus,
                          })
                      "
                    ></v-switch>
                  </td>
                  <td>
                    <router-link
                      :to="`/dashboard/addjob/${item.userHandle}`"
                      class="btn-dark addJobButton"
                      >Add Job</router-link
                    >
                  </td>
                  <td class="text-right">
                    <router-link
                      :to="'/dashboard/company/add/' + item.userHandle"
                      ><v-icon class="icons-action">
                        mdi-pencil
                      </v-icon></router-link
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-if="allLoadingAuth">
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
          </div>
          <ul
            class="pagination-button"
            v-if="allCompanyUser.results != undefined && !allLoadingAuth"
          >
            <li>
              Page :
              <span v-if="allCompanyUser.results.pagination.next">{{
                (allCompanyUser.results.pagination.next - 1) * this.limit
              }}</span
              ><span v-if="!allCompanyUser.results.pagination.next">{{
                allCompanyUser.results.pagination.total
              }}</span>
              -
              {{ allCompanyUser.results.pagination.total }}
            </li>
            <li>
              <div>
                <v-btn
                  v-if="allCompanyUser.results.pagination.previous"
                  @click="prevPage(allCompanyUser.results.pagination.previous)"
                  class="btn-dark"
                  >Prev</v-btn
                >

                <v-btn
                  v-if="allCompanyUser.results.pagination.next"
                  @click="nextPage(allCompanyUser.results.pagination.next)"
                  class="btn-dark"
                  >Next</v-btn
                >
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Company",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Company List",
    },
    title: "",
    adsposition: "",
    valid: true,
    limit: 100,
    skip: 1,
    roles: ["admin", "user", "employee"],
    role: "user",
  }),
  methods: {
    ...mapActions(["getCompanylistDash", "putCompanyPaymentStatus"]),
    validate() {
      if (this.$refs.form.validate()) {
        this.getCompanylistDash({
          limit: this.limit,
          skip: this.skip,
          fullname: this.fullname,
        });
      }
    },
    nextPage(data) {
      this.getCompanylistDash({
        limit: this.limit,
        skip: data,
      });
    },
    prevPage(data) {
      this.getCompanylistDash({
        limit: this.limit,
        skip: data,
      });
    },
    async updateCompany(data) {
      await this.putCompanyPaymentStatus({
        userHandle: data.userHandle,
        paymentStatus: data.paymentStatus,
      });
    },
  },
  computed: {
    ...mapGetters(["allCompanyUser", "allLoadingAuth"]),
  },
  created() {
    this.getCompanylistDash({ limit: this.limit, skip: this.skip });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.form-section {
  padding: 15px 0;
  .col {
    padding: 0 10px;
  }
}
.filtertitle {
  margin-bottom: 10px;
}
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}

.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.pagination-button {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  .btn-dark {
    margin-top: 0;
    margin-left: 10px;
  }
}
.table-heading,
.pagination-button {
  border: 1px solid $menucolor;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
}
.icons-action {
  margin: 10px;
  font-size: $iconfont;
}
.addJobButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  border-radius: 30px;
}
</style>
